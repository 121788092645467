<template>
  <div id="components-layout-demo-basic">
    <a-layout>
      <a-layout-header>
        <a-affix :offset-top="0">
          <div class="logo">
            <i>
              <img :src='img.logo' alt="One Forest Centre">
            </i>
            <span>One Forest Centre</span>
          </div>
          <div class="logo">
            <h1>One Forest Centre <b>|</b> 11615 Forest Central Dr</h1>
            <h3>5,973 SF of Office Space Available in Dallas, TX</h3>
          </div>
        </a-affix>
        <div class="carousel">
          <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>Office Space</a-breadcrumb-item>
            <a-breadcrumb-item>Texas</a-breadcrumb-item>
            <a-breadcrumb-item>Dallas</a-breadcrumb-item>
            <a-breadcrumb-item>11615 Forest Central Dr, Dallas, TX 75243</a-breadcrumb-item>
          </a-breadcrumb>
          <!-- <div v-for="item, index in 11" :key="index">
            <img :src="img[item + 1]" />
          </div> -->
          <a-carousel arrows autoplay variableWidth>
            <div slot="prevArrow" slot-scope="props" class="custom-slick-arrow" style="left: 10px;zIndex: 100">
              <a-icon class="icon" type="left-circle" />
            </div>
            <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px;zIndex: 100">
              <a-icon class="icon" type="right-circle" />
            </div>
            <div v-for="item, index in 11" :key="index">
              <img :src="img[item + 1]" />
            </div>
            <!-- <div><img :src='img[1]' alt="One Forest Centre"></div>
            <div><img :src='img[2]' alt="One Forest Centre"></div>
            <div><img :src='img[3]' alt="One Forest Centre"></div>
            <div><img :src='img[4]' alt="One Forest Centre"></div>
            <div><img :src='img[5]' alt="One Forest Centre"></div>
            <div><img :src='img[6]' alt="One Forest Centre"></div>
            <div><img :src='img[7]' alt="One Forest Centre"></div>
            <div><img :src='img[8]' alt="One Forest Centre"></div>
            <div><img :src='img[9]' alt="One Forest Centre"></div>
            <div><img :src='img[10]' alt="One Forest Centre"></div>
            <div><img :src='img[11]' alt="One Forest Centre"></div>
            <div><img :src='img[12]' alt="One Forest Centre"></div> -->
          </a-carousel>
        </div>
      </a-layout-header>
      <a-layout>
        <a-layout-content>
          <a-row type="flex" justify="center" align="top">
            <a-col :xs="24" :sm="24" :md="24" :lg="23" :xl="23">
              <section class="content">
                <h2>HIGHLIGHTS</h2>
                <a-row>
                  <a-col :span="12">
                    <ul class="list">
                      <li>Flexible Lease Terms and Competitive Rents</li>
                      <li>On-site Management</li>
                      <li>Controlled Access</li>
                    </ul>
                  </a-col>
                  <a-col :span="12">
                    <ul class="list">
                      <li>Forest Lane DART station nearby</li>
                      <li>Strong residential and business neighborhood</li>
                      <li>Easily accessible to I-635, US 75, and North Dallas Tollway</li>
                    </ul>
                  </a-col>
                </a-row>
              </section>
              <section class="content">
                <h2>ALL AVAILABLE SPACES({{dataCount}})</h2>
                <a-table :columns="columns" :data-source="data" class="components-table-demo-nested" :pagination="false">
                  <div class="expend" slot="expandedRowRender" slot-scope="record" style="margin: 0">
                    <!-- {{ record.description }} -->
                    <a-row>
                      <a-col :span="24">
                        <img class="expendImg" alt="" :src="'/pic/' + record.img" />
                      </a-col>
                      <a-col :span="24">
                        <div style="display:flex;">
                          <ul>
                            <li v-for="(item1, index1) in record.description1" :key="index1">{{item1}}</li>
                          </ul>
                          <ul>
                            <li v-for="(item2, index2) in record.description2" :key="index2">{{item2}}</li>
                          </ul>
                        </div>
                      </a-col>
                    </a-row>
                  </div>
                  <!-- <a-table
                    slot="expandedRowRender"
                    :columns="innerColumns"
                    :data-source="innerData"
                    :pagination="false"
                  >
                    <span slot="status"> <a-badge status="success" />Finished </span>
                    <span slot="operation" class="table-operation">
                      <a>Pause</a>
                      <a>Stop</a>
                      <a-dropdown>
                        <a-menu slot="overlay">
                          <a-menu-item>
                            Action 1
                          </a-menu-item>
                          <a-menu-item>
                            Action 2
                          </a-menu-item>
                        </a-menu>
                        <a> More <a-icon type="down" /> </a>
                      </a-dropdown>
                    </span>
                  </a-table> -->
                </a-table>
              </section>
              <section class="content">
                <h2>PROPERTY OVERVIEW</h2>
                <a-row>
                  <a-col :span="12">
                    <p>51,450 sf, 3 story, full service office building. On site management and security, 24 hour HVAC, covered parking available. Spaces available for immediate occupancy. Great location. Competitive rents.<br> Great Location near SEC of Forest Lane and Central</p>
                  </a-col>
                  <a-col :span="12">
                    <p>Expressway ( US Hwy 75). Forest Lane DART Station nearby. Easy access to I-635, US Hwy 75 and North Dallas Tollway. Numerous restaurants, retail, service, and institutions in the area including US Post office, FedEX/Kinkos, Home Depot, Medical City.</p>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12">
                    <a-row>
                      <a-col :span="12">
                        <ul class="list">
                          <li>24 Hour Access</li>
                          <li>Atrium</li>
                          <li>Bus Line</li>
                        </ul>
                      </a-col>
                      <a-col :span="12">
                        <ul class="list">
                          <li>Controlled Access</li>
                          <li>Commuter Rail</li>
                          <li>Metro/Subway</li>
                        </ul>
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col :span="12">
                    <a-row>
                      <a-col :span="12">
                        <ul class="list">
                          <li>Property Manager on Site</li>
                          <li>Security System</li>
                          <li>Signage</li>
                        </ul>
                      </a-col>
                      <a-col :span="12">
                        <ul class="list">
                          <li>Central Heating</li>
                          <li>Fully Carpeted</li>
                          <li>Air Conditioning</li>
                        </ul>
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
              </section>
              <section class="content">
                <h2>PROPERTY FACTS</h2>
                <a-row>
                  <a-col :span="8">
                    <dl>
                      <dt>Building Type</dt>
                      <dd>Office</dd>
                    </dl>
                    <dl>
                      <dt>Year Built</dt>
                      <dd>1980</dd>
                    </dl>
                    <dl>
                      <dt>Parking</dt>
                      <dd>Surface Parking<br>Covered Parking at $35/month</dd>
                    </dl>
                  </a-col>
                  <a-col :span="8">
                    <dl>
                      <dt>Building Height</dt>
                      <dd>3 Stories</dd>
                    </dl>
                    <dl>
                      <dt>Building Size</dt>
                      <dd>51,450 SF</dd>
                    </dl>
                  </a-col>
                  <a-col :span="8">
                    <dl>
                      <dt>Building Class</dt>
                      <dd>B</dd>
                    </dl>
                    <dl>
                      <dt>Typical Floor Size</dt>
                      <dd>17,150 SF</dd>
                    </dl>
                  </a-col>
                </a-row>
              </section>
              <section class="map">
                <h2>MAP</h2>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3349.629817078943!2d-96.76990698452951!3d32.907954384604125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c201265cd1fd5%3A0x99c8a355334a2183!2s11615%20Forest%20Central%20Dr%2C%20Dallas%2C%20TX%2075243!5e0!3m2!1sen!2sus!4v1641111557865!5m2!1sen!2sus" width="100%" height="450" style="border:0;display: block;" allowfullscreen="" loading="lazy"></iframe>
              </section>
              <section class="content transit">
                <h2>TRANSPORTATION</h2>
                <h5><mdicon class="cIcon" name="train-variant" />TRANSIT/SUBWAY</h5>
                <a-row class="line">
                  <a-col :span="16">
                    Forest Lane Transit Stop
                    <i><img :src='img.dart' alt="dart"/></i>
                    <i><img :src='img.redline' alt="redline"/></i>
                    <i><img :src='img.orangeline' alt="orangeline"/></i>
                  </a-col>
                  <a-col :span="4" class="toRight">
                    9 min walk
                  </a-col>
                  <a-col :span="4" class="toRight">
                    0.4 mi
                  </a-col>
                </a-row>
                <h5 class="mt30"><mdicon class="cIcon" name="airplane" />AIRPORT</h5>
                <a-row class="line">
                  <a-col :span="16">
                    Dallas Love Field Airport
                  </a-col>
                  <a-col :span="4" class="toRight">
                    19 min drive
                  </a-col>
                  <a-col :span="4" class="toRight">
                    9.9 mi
                  </a-col>
                </a-row>
                <a-row class="line">
                  <a-col :span="16">
                    Dallas/Fort Worth International Airport
                  </a-col>
                  <a-col :span="4" class="toRight">
                    31 min drive
                  </a-col>
                  <a-col :span="4" class="toRight">
                    22.6 mi
                  </a-col>
                </a-row>
              </section>
            </a-col>
          </a-row>
        </a-layout-content>
        <a-layout-sider>
          <a-affix :offset-top="140">
            <a-card class="card">
              <dl class="manager">
                <dt>
                  <img :src='img.manager' alt="One Forest Centre">
                </dt>
                <dd>
                  <h4>Daisy</h4>
                  <h4>Liu</h4>
                </dd>
              </dl>
              <h3><mdicon class="rIcon" name="phone" />972-922-4582</h3>
              <h2>Cloister Properties LLC</h2>
              <p>11615 Forest Central Dr, Suite 108,<br>Dallas, TX 75243</p>
            </a-card>
          </a-affix>
        </a-layout-sider>
      </a-layout>
      <a-layout-footer>
        <!-- <div class="bottom">
          <i>
            <img :src='img.logo' alt="One Forest Centre">
          </i>
          <span>One Forest Centre</span>
        </div> -->
        <div>
          <p>© 2021 One Forest Centre</p>
        </div>
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
export default {
  name: 'App',
  components: {
    // HelloWorld
  },
  data() {
    return {
      img: {
        logo: require('@/assets/img/logo.png'),
        manager: require('@/assets/img/manager.jpg'),
        dart: require('@/assets/img/dart.png'),
        redline: require('@/assets/img/redline.png'),
        orangeline: require('@/assets/img/orangeline.png'),
        1: require('@/assets/img/1.jpg'),
        2: require('@/assets/img/2.jpg'),
        3: require('@/assets/img/3.jpg'),
        4: require('@/assets/img/4.jpg'),
        5: require('@/assets/img/5.jpg'),
        6: require('@/assets/img/6.jpg'),
        7: require('@/assets/img/7.jpg'),
        8: require('@/assets/img/8.jpg'),
        9: require('@/assets/img/9.jpg'),
        10: require('@/assets/img/10.jpg'),
        11: require('@/assets/img/11.jpg'),
        12: require('@/assets/img/12.jpg')
      },
      columns: [
        { title: 'SPACE', dataIndex: 'space', key: 'space' },
        { title: 'SIZE', dataIndex: 'size', key: 'size' },
        { title: 'TERM', dataIndex: 'term', key: 'term' },
        { title: 'RATE', dataIndex: 'rate', key: 'rate' },
        { title: 'SPACE USE', dataIndex: 'spaceuse', key: 'spaceuse' },
        { title: 'CONDITION', dataIndex: 'condition', key: 'condition' },
        { title: 'AVAILABLE', dataIndex: 'available', key: 'available' },
      ],
      innerColumns: [
        { title: 'Date', dataIndex: 'date', key: 'date' },
        { title: 'Name', dataIndex: 'name', key: 'name' },
        { title: 'Status', key: 'state', scopedSlots: { customRender: 'status' } },
        { title: 'Upgrade Status', dataIndex: 'upgradeNum', key: 'upgradeNum' },
        {
          title: 'Action',
          dataIndex: 'operation',
          key: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      innerData: [],
      data: []
    }
  },
  computed: {
    dataCount () {
      return this.data.length
    }
  },
  methods: {
    run: function () {
      let that = this
      this.$axios.get('/20220101.json', { params: {name: '20220101'} }).then((data) => {
        console.log(data)
        that.processData(data.data)
      }).catch(error => {
        // console.log(error)
      })
    },
    processData: function (data) {
      this.data = []
      let len = data.length
      console.log('data', len, data)
      for (let i = 0; i < len; i++) {
        this.data.push({
          key: i,
          space: data[i].space,
          size: data[i].size,
          term: data[i].term,
          rate: data[i].rate,
          spaceuse: data[i].spaceuse,
          condition: data[i].condition,
          available: data[i].available,
          description1: data[i].description1,
          description2: data[i].description2,
          img: data[i].img
        })
      }
    }
  },
  mounted() {
    // for (let i = 0; i < 3; ++i) {
    //   this.innerData.push({
    //     key: i,
    //     date: '2014-12-24 23:12:00',
    //     name: 'This is production name',
    //     upgradeNum: 'Upgraded: 56',
    //   });
    // }
    // for (let i = 0; i < 3; ++i) {
    //   this.data.push({
    //     key: i,
    //     space: 'Screem',
    //     size: 'iOS',
    //     term: '10.3.4.5654',
    //     rate: 500,
    //     spaceuse: 'Jack',
    //     condition: '2014-12-24 23:12:00',
    //     available: 'yes',
    //     description1: ['Avenir', '-webkit-font-smoothing', 'Avenir, Helvetica, Arial, sans-serif'],
    //     description2: ['1Avenir', '2-webkit-font-smoothing', '3Avenir, Helvetica, Arial, sans-serif']
    //   });
    // }
    this.run()
  },
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
#components-layout-demo-basic {
  text-align: center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
#components-layout-demo-basic .ant-layout-header,
#components-layout-demo-basic .ant-layout-footer {
  background: white;
  color: #2c3e50;
}
#components-layout-demo-basic .ant-layout-footer {
  line-height: 1.5;
  background: #63666a;
  color: white;
}
#components-layout-demo-basic .ant-layout-sider {
  background: white;
  color: #2c3e50;
  line-height: 120px;
}
#components-layout-demo-basic .ant-layout-content {
  background: white;
  color: #2c3e50;
  min-height: 120px;
  line-height: 120px;
}
// #components-layout-demo-basic > .ant-layout {
//   margin-bottom: 48px; 
// }
#components-layout-demo-basic > .ant-layout:last-child {
  margin: 0;
}
.icon {
  color: rgba(255, 255, 255, 0.8)!important;
}
.content, .carousel {
  text-align: left;
}
.breadcrumb {
  padding: 10px!important;
}
.ant-layout-header {
  height: auto!important;
  padding: 0!important;
}
.logo {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.1);
  line-height: 1;
  background: #fff;
  i {
    width: 40px;
    display: inline-block;
    padding: 0px;
    margin-right: 10px;
  }
  span {
    font-size: 20px;
    font-weight: bold;
  }
  img {
    width: 100%;
    vertical-align: middle;
  }
  h1 {
    font-size: 34px;
    font-weight: bold;
    margin: 0;
    line-height: 1;
    padding: 10px 0;
  }
  b {
    color: rgba(0, 0, 0, 0.25);
  }
  h3 {
    font-size: 24px;
    font-weight: 100;
    font-family: Helvetica,Arial,sans-serif;
  }
}
.bottom {
  line-height: 1;
  text-align: left;
  i {
    width: 40px;
    display: inline-block;
    padding: 0px;
    margin-right: 10px;
  }
  span {
    font-size: 20px;
    font-weight: bold;
  }
  img {
    width: 100%;
    vertical-align: middle;
  }
}
.carousel {
  margin-bottom: 25px;
}
section.map {
  text-align: left;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  h2 {
    font-size: 24px;
    border-bottom: 1px solid rgb(221, 221, 221);
    line-height: 2;
    font-weight: bold;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: bold;
    color: rgb(0, 0, 0);
    padding-right: 15px;
    padding-top: 0;
    padding-bottom: 15px;
    line-height: 1.5;
  }
}
section.content {
  text-align: left;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  h2 {
    // padding-left: 30px;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: bold;
    color: rgb(0, 0, 0);
    padding-right: 15px;
    padding-top: 0;
    padding-bottom: 15px;
    line-height: 1.5;
  }
  ul.list {
    // margin-right: 30px!important;
    column-count: inherit;
    column-gap: inherit;
    margin-bottom: -13px!important;
    margin: 10px 0 0 0!important;
    line-height: 1.5;
    padding: 0!important;
    li {
        float: none;
        display: inline-block;
        vertical-align: top;
        line-height: 25px;
        padding: 0 0 15px 20px;
        position: relative;
        font-size: 20px;
        width: 100%!important;
        margin-bottom: 0!important;
        margin-right: 0!important;
        margin-left: 0!important;
        -webkit-column-break-inside: avoid;
        backface-visibility: hidden;
      &::before{
        font-size: 1rem;
        content: "";
        width: 8px;
        height: 8px;
        position: absolute;
        left: 0;
        top: 10px;
        background-color: rgb(207, 0, 0);
        border-radius: 50%;
        color: rgb(207, 0, 0);
        margin-top: -0.1rem;
      }
    }
  }
  p {
    white-space: pre-wrap;
    font-size: 20px;
    font-weight: 100;
    line-height: 32px;
    color: rgb(0, 0, 0);
    -webkit-font-smoothing: antialiased;
    margin: 0;
  }
  dl {
    clear: both;
    width: 100%;
    line-height: 2;
  }
  dt {
    width: 50%;
    font-size: 20px;
    font-weight: 100;
    float: left;
  }
  dd {
    width: 50%;
    font-size: 20px;
    font-weight: bold;
    float: left;
  }
  h5 {
    margin: -5px 0 0!important;
    padding: 0!important;
    font-size: 20px;
    line-height: 20px;
    font-weight: 100;
    color: rgb(0, 0, 0);
  }
}
section.transit {
  font-size: 20px;
  line-height: 2;
  .mt30 {
    margin-top:30px!important;
  }
  .toRight{
    text-align: right;
  }
  i {
    display: inline-block;
    margin-left: 8px;
  }
  img {
    width: 28px;
    height: 28px;
    vertical-align: middle;
  }
  .line {
    border-bottom: 1px solid rgb(229, 229, 229);
  }
}
.anticon.cIcon {
  position: relative;
  top: 3px;
  margin-right: 10px;
  font-size: 10px;
}
.rIcon{
  // position: relative;
  // top: 3px;
  // margin-right: 10px;
  // font-size: 10px;
  color: rgb(207, 0, 0)!important;
}
.card {
  border: 1px solid rgb(207, 0, 0)!important;
  border-radius: 5px!important;
  img {
    width: 100%;
    display: block;
  }
  dl {
    clear: both;
    &::after {
      content: '.';
      width: 0;
      height: 0;
      clear: both;
      visibility: hidden;
    }
  }
  dt, dd {
    width: 50%;
    // display: inline-block;
    float: left;
  }
  h4 {
    font-size: 28px;
    line-height: 1;
    padding-top: 10px;
    text-indent: 10px;
    vertical-align: top;
    margin-bottom: 0px;
  }
  h3 {
    font-size: 24px;
  }
}
.ant-anchor-ink {
  display: none;
}
.expend {
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  .expendImg {
    width: 100%;
    margin-bottom: 20px;
  }
  ul {
    width: 50%;
    margin: 0;
    list-style-type: none;
  }
  li {
    &::before {
      content: "";
      width: 8px;
      height: 8px;
      position: absolute;
      left: 0;
      top: 10px;
      background-color: rgb(207, 0, 0);
      border-radius: 50%;
    }
    font-size: 18px;
    font-weight: 100;
    position: relative;
    line-height: 25px;
    padding: 0 0 15px 20px;
    vertical-align: top;
  }
}
/* For demo */
.anticon svg {
  width: 30px!important;
  height: 30px!important;
}
.ant-carousel {
  height:560px;
  position: relative;
}
.ant-carousel .slick-slider {
  text-align: center;
  height: 560px;
  line-height: 560px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slider h3 {
  color: #fff;
}
.slick-track {
  height: 100%;
}
.ant-carousel img{
  height: 560px;
  // width: auto;
}
</style>

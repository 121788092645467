import Vue from 'vue'
import Antd from 'ant-design-vue';
import App from './App.vue'
import mdiVue from 'mdi-vue/v2'
import * as mdijs from '@mdi/js'
import axios from 'axios'
import 'ant-design-vue/dist/antd.css'
// import '@/assets/style/theme.less'
axios.defaults.baseURL = '/api'
Vue.prototype.$axios = axios
Vue.use(Antd);
Vue.use(mdiVue, {
  icons: mdijs
}) 
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
